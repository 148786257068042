import React, { useState } from "react"
import "./tooltip.scss"
import DOMPurify from "dompurify";

const Tooltip = (props: TooltipInterface) => {
	const {
		title,
		text,
		id,
		textString
	} = props
	
	const [isOpen, setOpen] = useState(true);
	
	const close = (e) => {
		e.stopPropagation();
		setOpen(false);
	}
	
	const open = () => {
		setOpen(true);
	}

	return (
		<div id={id + "-help-tip"} className="help-tip" onClick={() => open()}>
			<section id={id} className={isOpen ? '': 'hiden'}>
				<div className="tooltip-close-icon">
					<i className="icon__check icon-close" onClick={(e) => close(e)}/>
				</div>
				<span className="tooltip-title">{ title }</span>
				{textString && <p>{textString}</p>}
				<p dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(text, { ADD_ATTR: ['target'] })
						}}></p>
			</section>
		</div>
	)
}

interface TooltipInterface {
	title?: string;
	text?: JSX.Element;
	id: string;
	textString?: string;
}

export default Tooltip;
